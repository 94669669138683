import gsap from "gsap";
import AbstractComponent from "./abstract-component";

export default class JoinForm extends AbstractComponent {

    constructor(el) {
        super(el);
        this.open = false;
        this.DOM = {};
        this.initDomElements();
        this.initEvents();
        this.checkHash();
    }

    initDomElements() {
        this.DOM = {};
        this.DOM.form = this.$el.querySelector('.wpcf7');
        this.DOM.closeBtn = this.$el.querySelector('.close-btn');
        this.DOM.inputs = this.$el.querySelectorAll('input.wpcf7-form-control,textarea.wpcf7-form-control');
        this.DOM.selects = this.$el.querySelectorAll('select');      
        this.DOM.formWrapper = this.$el.querySelector('.c-form-popin__inner');
        this.DOM.successMessage = this.$el.querySelector('.success-message');
        this.DOM.response = this.$el.querySelector('.wpcf7-response-output');
        this.DOM.popinHash = this.$el.getAttribute('data-popin-hash'); // Récupère la valeur de l'attribut data-popin-hash
    }

    removeHash() {
        history.replaceState(null, null, window.location.pathname + window.location.search);
    }

    initEvents() {
        this.DOM.inputs.forEach(input => {
            input.addEventListener('focus', this.handleFocus.bind(this));
            input.addEventListener('blur', this.handleBlur.bind(this));
        });

        this.DOM.closeBtn.addEventListener('click', () => {
            this.removeHash();
            this.checkHash();
        });

        this.DOM.selects.forEach(select => {
            select.addEventListener('change', () => {
                const label = select.closest('div').querySelector('label');
                label.style.display = select.value !== "" ? "none" : "block";
            });
        });

        // Ajoute un événement pour détecter les erreurs dans le formulaire
        this.DOM.form.addEventListener('wpcf7invalid', this.handleValidationErrors.bind(this));

        // Ajoute un événement pour réinitialiser les erreurs lors de la soumission
        this.DOM.form.addEventListener('wpcf7submit', this.handleValidationErrors.bind(this));

        this.DOM.form.addEventListener('wpcf7mailsent', () => {
            gsap.to(this.DOM.form, { autoAlpha: 0, duration: 0.5,ease: "0.14, 1.00, 0.34, 1.00", onComplete: () => {
                gsap.set(this.DOM.form, { display: 'none' });
                gsap.set(this.DOM.successMessage, { display: 'block' });
                gsap.fromTo(this.DOM.successMessage, { x: 5 }, { x: 0, duration: 0.5,delay:0.2,ease: "0.14, 1.00, 0.34, 1.00" });
            }});
        });

        // Écoute les changements de hash
        window.addEventListener('hashchange', this.checkHash.bind(this));
    }

    checkHash() {
        const currentHash = window.location.hash.substring(1);
        this.handleHashChange(currentHash);
    }

    handleHashChange(hash) {
        if (hash === this.DOM.popinHash) {
            this.$el.classList.add('active');
            this.open = true;
            if (!this.isIOS()) {
                this.app.emit("disable-scroll");
            }else{
                document.body.classList.add('fixed')
            }
        } else {
            this.$el.classList.remove('active');
            if (this.open) {
                setTimeout(() => {
                    this.app.emit("enable-scroll");
                    this.open = false;
                    document.body.classList.remove('fixed')
                }, 1000);
            }
        }
    }

    // Fonction pour détecter si l'utilisateur est sur un appareil iOS
    isIOS() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }

    handleFocus(e) {
        const container = e.target.closest('p');
        container.classList.add('focus');
    }

    handleBlur(e) {
        const container = e.target.closest('p');
        if (!e.target.value) {
            container.classList.remove('focus');
        }
    }

    resetValidationErrors() {
        this.DOM.inputs.forEach(input => {
            input.closest('p').classList.remove('invalid');
        });
        this.DOM.selects.forEach(select => {
            select.closest('p').classList.remove('invalid');
        });
    }

    handleValidationErrors(e) {
        console.log(e);
        
        this.resetValidationErrors();
        e.detail.apiResponse.invalid_fields.forEach(field => {
            const inputElement = this.$el.querySelector(`[name="${field.field}"]`);
            if (inputElement) {             
                inputElement.closest('p').classList.add('invalid');
            }
        });
    }

    destroy() { }
}
