import request from "../vendors/request";
import AbstractComponent from "./abstract-component";
import { gsap } from 'gsap';

export default class ArticleList extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.request = null;
        this.class = "c-articles-list"; // Adjusted class name for Article list
        this.action = "filter_articles_ajax"; // Adjusted action name for filtering Articles
        this.params = {
            tag1: null,
            tag2: null,
            tag3: null,
            paged: 1
        };
        this.lastFetch = null;
        this.initDomElements();
        this.initEvents();
    }

    initDomElements() {
        this.DOM.column = this.$el.querySelector('.' + this.class + '__list'); // Adjusted class name for Article list
        this.DOM.noPostMsg = this.$el.querySelector('.no-post');
        this.DOM.moreBtn = this.$el.querySelector('.moreBtn');
        this.DOM.selects = this.$el.querySelectorAll('select');
        this.DOM.resetBtn = this.$el.querySelector('.reset');
    }

    initEvents() {
        if (this.DOM.moreBtn) {
            this.DOM.moreBtn.addEventListener('click', (e) => {
                e.preventDefault();
                this.getNextPage();
            });
        }
        this.DOM.resetBtn.addEventListener('click',(e)=>{
            e.preventDefault();
            this.resetFilters()
        })
        this.DOM.selects.forEach(select => {
            select.addEventListener('change', () => {
                const filterName = select.getAttribute('name');
                const filterValue = select.value;
                
                this.lastFetch = 'filter'
                this.params[filterName] = filterValue;
                if (Object.keys(this.params).every(key => !this.params[key])) {
                    
                    this.app.gsap.fromTo(this.DOM.resetBtn, { autoAlpha: 1, x: 0 }, { autoAlpha: 0, x: -5 ,onComplete:()=>{
                        this.app.gsap.set(this.DOM.resetBtn, { display: 'none' })
                    }})
                } else {
                    this.app.gsap.set(this.DOM.resetBtn, { display: 'flex' })
                    this.app.gsap.fromTo(this.DOM.resetBtn, { autoAlpha: 0, x: 5 }, {
                        autoAlpha: 1, x: 0})
                }
                this.params.paged = 1;
                this.fetchArticles();
            });
        });
    }

    updateUrlParams() {
        const urlParams = new URLSearchParams();
        let hasReset = false
        if (this.params.tag1) {
            hasReset = true
            urlParams.set('tag1', this.params.tag1);
        }
        if (this.params.tag2) {
            hasReset = true
            urlParams.set('tag2', this.params.tag2);
        }
        if (this.params.tag3) {
            hasReset = true
            urlParams.set('tag3', this.params.tag3);
        }
        // urlParams.set('paged', this.params.paged);
        if (hasReset) {
            this.DOM.resetBtn.classList.add('visible')
        }
        const newUrl = `${window.location.pathname.replace(/\/page\/\d+\//, '/')}${urlParams.toString() ? '?' + urlParams.toString() : ''}`;
        history.pushState({}, '', newUrl);
    }

    getNextPage() {
        this.params.paged++;
        this.lastFetch = 'next';
        this.fetchArticles(); // Adjusted method name for fetching Articles
    }

    fetchArticles() { // Adjusted method name for fetching Articles
        this.updateUrlParams();
        if (this.DOM.moreBtn) {
            this.DOM.moreBtn.classList.add('is-loading');
        }

        this.request = request.AJAX({
            'url': window.JWP.ajax_url + "?action=" + this.action,
            'data': this.params,
            'success': this.onSuccess.bind(this)
        });
    }
    resetFilters() {
        this.params.tag1 = null;
        this.params.tag2 = null;
        this.params.tag3 = null;
        this.DOM.resetBtn.classList.remove('visible')
        this.DOM.selects.forEach(select=>{
            select.value = select.options[0].value
        })
        this.params.paged = 1; // Réinitialiser la page à 1 après la réinitialisation des filtres
        this.fetchArticles(); // Recharger les événements après la réinitialisation des filtres
        this.app.gsap.fromTo(this.DOM.resetBtn, { autoAlpha: 1, x: 0 }, {
            autoAlpha: 0, x: -5, onComplete: () => {
                this.app.gsap.set(this.DOM.resetBtn, { display: 'none' })
            }
        })
    }
    onSuccess(response) {
        const data = response.data;
        if (this.lastFetch === 'filter') {
            this.clearColumn();
        }

        if (data.articles) { // Adjusted key for articles data
            data.articles.forEach(article => { // Adjusted key for article data
        
                this.DOM.column.innerHTML += article.html; // Adjusted key for article html
            });
            if (this.DOM.moreBtn) {
                this.DOM.moreBtn.classList.remove('is-loading');
            }
            setTimeout(() => {
                this.app.$scroll.update();
            }, 200);
        }

        gsap.set(this.DOM.noPostMsg, { display: !data.articles.length && this.lastFetch === "filter" ? 'block' : 'none' });
        gsap.set(this.DOM.moreBtn, { display: data.has_more ? 'flex' : 'none' });
    }

    clearColumn() {
        this.DOM.column.innerHTML = '';
    }

    destroy() {
        // Clean up event listeners or other resources
    }
}
