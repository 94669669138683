import Swiper from "swiper";
import { Autoplay, FreeMode, Navigation } from 'swiper/modules';
import AbstractComponent from "./abstract-component";
import gsap from "gsap";

Swiper.use([FreeMode, Autoplay, Navigation]);
export default class Challenges extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.currentTab = 0
        this.initDomElements();
        this.initSliders();
        this.initEvents();
    }

    initDomElements() {
        this.DOM = {}
        this.DOM.sliders = this.$el.querySelectorAll('.swiper')
        this.DOM.tabs = this.$el.querySelectorAll('.c-challenges__tabs button')
    }
    initEvents(){
        this.DOM.tabs.forEach(tab => {
            tab.addEventListener('click',()=>{
                this.DOM.tabs[this.currentTab].classList.add('no-bg')
                gsap.to(this.DOM.sliders[this.currentTab].querySelectorAll('.swiper-slide'),{
                    y:-10,autoAlpha:0,duration:0.2,stagger:{each:0.1},onStart:()=>{
                        this.DOM.tabs.forEach(tab => {
                            tab.style.pointerEvents = 'none'
                        });
                        tab.classList.remove('no-bg')
                    },onComplete:()=>{
                        this.DOM.sliders[this.currentTab].querySelector('.row').classList.remove('visible')
                        this.currentTab = tab.dataset.index
                        this.DOM.sliders[this.currentTab].querySelector('.row').classList.add('visible')
                        gsap.fromTo(this.DOM.sliders[this.currentTab].querySelectorAll('.swiper-slide'),{y:10,autoAlpha:0},{
                            y:0,autoAlpha:1,duration:0.2,stagger:{each:0.1},onComplete:()=>{
                                this.DOM.tabs.forEach(tab => {
                                    tab.style.pointerEvents = ''
                                });
                            }
                        })
                    }
                })


            })
        });
    }
    initSliders() {
        this.DOM.sliders.forEach(slider => {
            this.sliderObject = new Swiper(slider, {
                loop: false,
                allowTouchMove: true,
                centeredSlides: false,
                slidesPerView: 1.45,
                spaceBetween: 20,
                breakpoints: {
                    '576': {
                        slidesPerView: 3,
                    },
                    '879.98': {
                        slidesPerView: 3,
                    },
                    '1189.98': {
                        enabled: false,
                        slidesPerView: 5,
                    },
                },
            });
        });

    }
    destroy() { }
}