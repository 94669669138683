import Lottie from "lottie-web";
import AbstractComponent from "./abstract-component";

export default class TextImage extends AbstractComponent {
    constructor(el) {
        super(el);
        this.DOM = {};
        this.initDomElements();
        this.initLottie();
    }

    initDomElements() {
        this.DOM.lottie = this.$el.querySelector('[data-lottie]');
    }

    initLottie() {
        if (this.DOM.lottie) {
            const lottieUrl = this.DOM.lottie.getAttribute('data-lottie');
            
            Lottie.loadAnimation({
                container: this.DOM.lottie,
                renderer: 'svg',           
                loop: true,                 
                autoplay: true,            
                path: lottieUrl             
            });
        }
    }

    destroy() {
    }
}
