import Swiper from "swiper";
import { Autoplay, FreeMode, Navigation } from 'swiper/modules';
import AbstractComponent from "./abstract-component";

Swiper.use([FreeMode, Autoplay, Navigation]);

export default class Testimony extends AbstractComponent {
    constructor(el) {
        super(el);
        this.DOM = {};
        this.current = 0;
        this.initDomElements();
        this.initSlider();
        this.updateSliderHeight();  // Update height on init
        window.addEventListener('resize', this.updateSliderHeight.bind(this));  // Update height on resize
    }

    initDomElements() {
        this.DOM = {};
        this.DOM.slider = this.$el.querySelector('.swiper');
        this.DOM.slides = this.$el.querySelectorAll('.slide');
        this.DOM.imgs = this.$el.querySelectorAll('img,.placeholder');
        this.DOM.quotes = this.$el.querySelectorAll('.quote');
        this.DOM.sliderArrowNext = this.$el.querySelector('.c-testimony__arrow.next');
        this.DOM.sliderArrowPrev = this.$el.querySelector('.c-testimony__arrow.prev');
    }

    updateSliderHeight() {
        // Affiche toutes les slides temporairement pour mesurer leur hauteur
        this.DOM.slides.forEach(slide => slide.style.display = 'block');
    
        // Trouve la slide la plus haute et l'affiche
        let maxHeightSlide = null;
        let maxHeight = 0;
    
        this.DOM.slides.forEach(slide => {
            const slideHeight = slide.offsetHeight;
            if (slideHeight > maxHeight) {
                maxHeight = slideHeight;
                maxHeightSlide = slide;
            }
            slide.style.display = 'none'
        });        
        if (maxHeightSlide) {
            maxHeightSlide.style.display = 'block';
            this.DOM.slider.style.minHeight = `${this.DOM.slider.offsetHeight}px`;
        }
    
        // Réaffiche seulement la slide active
        this.DOM.slides.forEach((slide, index) => {
            slide.style.display = index === this.current ? 'block' : 'none';
        });
    }
    
    

    disableArrows() {
        this.app.gsap.set([this.DOM.sliderArrowPrev, this.DOM.sliderArrowNext], {
            pointerEvents: 'none'
        });
    }

    enableArrows() {
        this.app.gsap.set([this.DOM.sliderArrowPrev, this.DOM.sliderArrowNext], {
            pointerEvents: 'all'
        });
    }
    cleanPrevious(previous){
        this.app.gsap.set(previous, { display: 'none' });
        this.app.gsap.set(this.DOM.slides[this.current], { display: 'block' });
    }
    handleNext() {
        this.disableArrows();
        this.handlePrevImage(this.DOM.imgs[this.current], this.DOM.imgs[this.current + 1 < this.DOM.imgs.length ? this.current + 1 : 0]);
        this.app.gsap.fromTo(this.DOM.slides[this.current], { y: 0, autoAlpha: 1 }, { y: 10, autoAlpha: 0, ease: "0.14, 1.00, 0.34, 1.00",  onComplete: () => {
            const previous = this.DOM.slides[this.current];
            if (this.current + 1 < this.DOM.slides.length) {
                this.current++;
            } else {
                this.current = 0;
            }
            this.app.gsap.fromTo(this.DOM.slides[this.current], { y: 10, autoAlpha: 0 }, { y: 0, autoAlpha: 1, ease: "0.14, 1.00, 0.34, 1.00",delay:0.2,  onComplete: () => {
                this.enableArrows();
            }, onStart: ()=>{
                this.cleanPrevious(previous)
            }});
        }});
    }

    handlePrev() {
        this.disableArrows();
        this.handleNextImage(this.DOM.imgs[this.current], this.DOM.imgs[this.current - 1 >= 0 ? this.current - 1 : this.DOM.imgs.length - 1]);
        this.app.gsap.fromTo(this.DOM.slides[this.current], { y: 0, autoAlpha: 1 }, { y: 10, autoAlpha: 0, ease: "0.14, 1.00, 0.34, 1.00",  onComplete: () => {
            const previous = this.DOM.slides[this.current];
            if (this.current - 1 >= 0) {
                this.current--;
            } else {
                this.current = this.DOM.slides.length - 1;
            }
            this.app.gsap.fromTo(this.DOM.slides[this.current], { y: 10, autoAlpha: 0 }, { y: 0, autoAlpha: 1,delay:0.2,ease: "0.14, 1.00, 0.34, 1.00",  onComplete: () => {
                this.enableArrows();
            }, onStart: ()=>{
                this.cleanPrevious(previous)
            }});
        }});
    }

    handlePrevImage(previous, next) {
        this.app.gsap.set(previous, { zIndex: '0' });
        if (next.tagName == "DIV") {
            this.app.gsap.to(this.DOM.imgs, { autoAlpha: 0 , delay:0.25,ease: "0.14, 1.00, 0.34, 1.00",});
        }else{
            this.app.gsap.set(next, { zIndex: '2' ,autoAlpha:1 });
            this.app.gsap.fromTo(next, { x: '100%' }, { x: 0, y: 0, delay:0.25,ease: "0.14, 1.00, 0.34, 1.00", });
        }

    }

    handleNextImage(previous, next) {
        this.app.gsap.set(previous, { zIndex: '0' });        
        if (next.tagName == "DIV") {
            this.app.gsap.to(this.DOM.imgs, { autoAlpha: 0, delay:0.25,ease: "0.14, 1.00, 0.34, 1.00", });
        }else{
            this.app.gsap.set(next, { zIndex: '2',autoAlpha:1 });
            this.app.gsap.fromTo(next, { x: '-100%' }, { x: 0, y: 0, delay:0.25,ease: "0.14, 1.00, 0.34, 1.00", });
        }
    }

    initSlider() {
        this.app.gsap.set(this.DOM.slides[this.current], { display: 'block' });
        if (this.DOM.slides.length > 1) {
            this.DOM.sliderArrowNext.addEventListener('click', this.handleNext.bind(this));
            this.DOM.sliderArrowPrev.addEventListener('click', this.handlePrev.bind(this));
        }
    }

    destroy() {
        window.removeEventListener('resize', this.updateSliderHeight.bind(this));
    }
}
