import Swiper from "swiper";
import YouTubePlayer from "youtube-player";
import { Autoplay, FreeMode, Navigation } from 'swiper/modules';

import AbstractComponent from "./abstract-component";

Swiper.use([FreeMode, Autoplay, Navigation]);

export default class Slider extends AbstractComponent {
    constructor(el) {
        super(el);
        this.DOM = {};
        this.initDomElements();
        this.initSlider();
    }

    initDomElements() {
        this.DOM.slider = this.$el.querySelector('.swiper');
        this.DOM.sliderWrapper = this.$el.querySelector('.swiper-wrapper');
        this.DOM.slides = this.$el.querySelectorAll('.swiper-slide');
        this.DOM.sliderArrowNext = this.$el.querySelector('.next');
        this.DOM.sliderArrowPrev = this.$el.querySelector('.prev');
    }

    initSlider() {
        if (this.DOM.slider) {
            this.sliderObject = new Swiper(this.DOM.slider, {
                allowTouchMove: true,
                centeredSlides: false,
                slidesPerView: 1.15,
                spaceBetween: 20,
                autoHeight:false,
                breakpoints: {
                    879.98: {
                        spaceBetween: 0,
                        slidesPerView: 1,
                        speed:1000,
                        autoHeight:true,
                        loop:true,
                    },
                },
                navigation: {
                    nextEl: this.DOM.sliderArrowNext,
                    prevEl: this.DOM.sliderArrowPrev,
                },
            });
            this.DOM.slides.forEach(slide => {
                const video = slide.querySelector('[data-video-id]')
                const cover = slide.querySelector('img')
                const playButton = slide.querySelector('.play-button')
                if (video) {
                    const player = YouTubePlayer(video, {
                        videoId: video.getAttribute('data-video-id'),
                        host: "https://www.youtube-nocookie.com",
                        height:'auto',
                        playerVars: {
                            autoplay: 0,
                            controls:true,
                            color: "white",
                            playsinline: 0,
                        },
                    });
                    cover.addEventListener('click',()=>{
                        player.playVideo()
                        this.app.gsap.to([cover,playButton],{autoAlpha:0,pointerEvents:"none",duration:1,onComplete:()=>{
                            this.app.gsap.set([cover,playButton],{display:"none"})
                        }})
                    })
                    playButton.addEventListener('click',()=>{
                        player.playVideo()
                        this.app.gsap.to([cover,playButton],{autoAlpha:0,pointerEvents:"none",duration:1,onComplete:()=>{
                            this.app.gsap.set([cover,playButton],{display:"none"})
                        }})
                    })
                }
            });
        }
    }
    destroy() {
    }
}
