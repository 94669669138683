import EventEmitter from "eventemitter3";
import components from "../components/_map";
import { debounce } from "lodash";
import GridDebug from '../utils/GridDebug';
import gsap from "gsap/all";
import { CustomEase, MotionPathPlugin } from "gsap/all";
import Utils from "../utils/utils";
import Dropdowns from "../components/dropdowns";
import LCS from "../components/locomotive-scroll";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

// import barba from "@barba/core";
gsap.registerPlugin(MotionPathPlugin)
gsap.registerPlugin(CustomEase);

export default class AppController extends EventEmitter {
	constructor() {
		super();
		if (!AppController.singleton) {
			AppController.singleton = this;
			this.components = [];
			this.gsap = gsap
			this.currentController = document.body.getAttribute('data-controller');
			this.components_map = components;
			this.init();
			return AppController.singleton;
		}
	}
	init() {
		this.$dropdowns = new Dropdowns();
		this.$scroll = new LCS();
		this.grid = new GridDebug(12, 0)
		this.initAppEvents();
		this.initDomElements();
		this.instantiateComponents();

		this.initViewportVhVar();
		this.initDetect();
		this.initTextareas();

	}
	initDomElements() {

	}
	initAppEvents() {

		window.addEventListener("resize", () => {

			debounce((event) => {
				this.emit("resize", event);
			}, 16)
		})

		window.addEventListener("scroll", (event) => {

			this.emit("scroll", event);
		})
		window.onhashchange = () => {
			this.emit("hash-change", window.location.href);
		};
		this.on("scrollTo", (elt) => {
			this.$scroll.scrollTo(elt);
		});

		// Navbar events
		this.on("hide-navbar", () => {
			this.$navbarContainer.classList.add('hidden');

		});
		this.on("show-navbar", () => {
			this.$navbarContainer.classList.remove('hidden');
		});
		this.on('add-bg-navbar', () => {
			this.$navbarContainer.classList.add('bg-white');
		})

		this.on("disable-scroll", () => {
			if (this.$scroll) {
				disableBodyScroll(document.body);
				this.$scroll.disable()
			}
		});
		this.on("enable-scroll", () => {
			if (this.$scroll) {
				enableBodyScroll(document.body);
				this.$scroll.enable()
			}
		});

		this.on('remove-bg-navbar', () => {
			if (!this.$navbar.classList.contains('--scrolling')) {
				this.$navbarContainer.classList.remove('bg-white');
			}
		})
	}
	initTextareas() {
		const tx = document.getElementsByTagName("textarea");
		for (let i = 0; i < tx.length; i++) {
			tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
			tx[i].addEventListener("input", OnInput, false);
		}

		function OnInput() {
			this.style.height = "auto";
			this.style.height = (this.scrollHeight) + "px";
		}
	}

	instantiateComponents() {
		const components = document.body.querySelectorAll("[data-component]");
		for (let i = 0; i < components.length; i++) {
			const component = components[i];
			const name = component.getAttribute("data-component");
			if (this.components_map.hasOwnProperty(name)) {
				const componentClass = this.components_map[name];
				if (componentClass) {
					this.components.push(new componentClass.default(component));
				}
			}
		}
	}

	initViewportVhVar() {
		this.updateViewportVhVar();
		window.addEventListener("resize", () => {
			this.updateViewportVhVar()
		});
	}
	updateViewportVhVar() {
		let vh = window.innerHeight;
		document.documentElement.style.setProperty('--vh', `${vh / 100}px`);
	}

	initDetect() {
		window.addEventListener("resize", () => {
			this.detectDevice();
		});
		this.detectDevice();
	}
	detectDevice = () => {
		let landscape = window.matchMedia("(orientation: landscape)");
		document.documentElement.setAttribute('data-device-notice', Utils.deviceType() == 'mobile' && landscape.matches && window.innerWidth < 1000);
	}

	static getInstance() {
		return AppController.singleton;
	}
}
