import AbstractComponent from "./abstract-component";

export default class Header extends AbstractComponent {

    constructor(el) {
        super(el)
        this.DOM = {};
        this.initDomElements();
        this.iniEvents();
    }

    initDomElements() {
        this.DOM.scroller = this.$el.querySelector('.c-header__scroller')
        
        this.video = this.$el.querySelector('video');
    }
    iniEvents() {
        if (this.DOM.scroller) {
            this.DOM.scroller.addEventListener('click', () => {
                this.app.emit('scrollTo', this.$el.nextElementSibling)
            })
        }
        document.addEventListener('click', this.playVideo.bind(this));
        document.addEventListener('touchstart', this.playVideo.bind(this));
        document.addEventListener('touchmove', this.playVideo.bind(this));
        document.addEventListener('scroll', this.playVideo.bind(this));
        document.addEventListener('keydown', this.playVideo.bind(this));
    }
    playVideo() {
        if (this.video && this.video.paused) {
            this.video.play();
        }
    }
    destroy() { }
}
