export default {
    'testimony': require( './testimony.js' ),
    'challenges': require( './challenges.js' ),
    'header': require( './header.js' ),
    'video': require( './video.js' ),
    'slider': require( './slider.js' ),
    'article-list': require( './article-list.js' ),
    'site-footer':require('./site-footer.js'),
    'form-popin':require('./form-popin.js'),
    'logos':require('./logos.js'),
    'text-image':require('./text-image.js')
}
